import React, { useState, usEffect, Component } from "react";

import SHADADDY from './hamza.png';
import './App.css';
import quotes from './quotes.js';

// get random quote every 24 hours and auto refresh, generate another button
// make sure every quote is different

function App() {
  const [quote, setQuote] = useState(quotes[Math.floor(Math.random() * quotes.length)]);
  const [refresh, setRefresh] = useState(false);
  const [refreshTime, setRefreshTime] = useState(0);
  return (
    <div className="App">
      <header className="App-header">
        <img src={SHADADDY} className="App-logo" alt="logo" />
      </header>

      <div className="Quote">
        <p>
          "{quote.quote}"
        </p>
      </div>

      <div className="Refresh">
        <button className="button" onClick={() => {
          setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
          setRefresh(true);
          setRefreshTime(refreshTime + 1);
        }}>
          <p>
            Generate another
          </p>
        </button>
        <p>
          {refreshTime}
        </p>
      </div>
    </div>
  );
}


export default App;
