// list of hamza's quotes

const quotes = [
    // quotes from https://pastebin.com/kKf1aycP

    {
        quote: "Assumptions are for assholes",
        index: 0
    },
    {
        quote: "Big words from a small mouth",
        index: 1
    },
    {
        quote: "Isn't it obvious?",
        index: 2
    },
    {
        quote: "Apparently allegedly",
        index: 3
    },
    {
        quote: "I wouldn’t be speaking if I was you",
        index: 4
    },
    {
        quote: "SHUT UP",
        index: 5
    },
    {
        quote: "SPEAK",
        index: 6
    },
    {
        quote: "REALLY?",
        index: 7
    },
    {
        quote: "OH YEAH",
        index: 8
    },
    {
        quote: "Phenomenally",
        index: 9
    },
    {
        quote: "you fudging FUDGING FUDGING BASTARD!!",
        index: 10
    },
    {
        quote: "JEEEEZ",
        index: 11
    },
    {
        quote: "AND?",
        index: 12
    },
    {
        quote: "Get out of here",
        index: 13
    },
    {
        quote: "Look whos speaking",
        index: 14
    },
    {
        quote: "GOD!!!!!",
        index: 15
    },
    {
        quote: "WOW!!",
        index: 16
    },
    {
        quote: "Wouldn’t you say?",
        index: 17
    },
    {
        quote: "Because I like it? AESTHETIC!",
        index: 18
    },
    {
        quote: "Speak or be spoken to",
        index: 19
    },
    {
        quote: "Big mouth that’s full of fudging bastard shit",
        index: 20
    },
    {
        quote: "JEEEZ CALM DOWN",
        index: 21
    },
    {
        quote: "I swear to almighty GOD!!!!!!!!!!!!!!!!!!!!",
        index: 22
    },
    {
        quote: "PLEAAAAAASE, SHUT UP",
        index: 23
    },
    {
        quote: "CUN",
        index: 24
    },
    {
        quote: "I KNOW RIGHT?",
        index: 25
    },
    {
        quote: "OH RIGHT",
        index: 26
    },
    {
        quote: "My language is more richer than english",
        index: 27
    },
    {
        quote: "exacTLEEE",
        index: 28
    },
    {
        quote: "That’s a big statement from a small mouth",
        index: 29
    },
    {
        quote: "Its bloody atrociously stupid",
        index: 30
    },
    {
        quote: "I am ABSOLUTELY not sexist",
        index: 31 
    },
    {
        quote: "Oh fogod sake!",
        index: 32
    },
    {
        quote: "THAT IS A FACT!!!",
        index: 33
    },
    {
        quote: "that’s the least of my concerns",
        index: 34
    },
    {
        quote: "I'll send you behind the sun",
        index: 35
    },
    {
        quote: "Big words from a small existence",
        index: 36
    },
    {
        quote: "Be a man and stop.",
        index: 37
    },
    {
        quote: "Unlike the manchild infront of us",
        index: 38
    },
    {
        quote: "I'm really scared",
        index: 39
    },
    {
        quote: "GET STUFFED",
        index: 40
    },
    {
        quote: "Making the opponent laugh",
        index: 41
    },
    {
        quote: "Sorry I cant hear",
        index: 42
    },
    {
        quote: "You're the one that’s gonna suffer in the end, not me.",
        index: 43
    },
    {
        quote: "I'm not worried about Max, the wind would do more than him",
        index: 44
    },
    {
        quote: "Your whole existence will be no more.",
        index: 45
    },
    {
        quote: "You send me behind the sun, I'll send you behind all reality",
        index: 46
    },
    {
        quote: "Don’t become an asshole smartass for everyone else",
        index: 47
    },
    {
        quote: "The word of an unsignificant existence",
        index: 48
    },
    {
        quote: "Earloss is starting to happen",
        index: 49    
    },
    {
        quote: "Its on the tongue of my tip",
        index: 50   
    },
    {
        quote: "WOW, That’s really small.",
        index: 51    
    },
    {
        quote: "Shes a fudging ungrateful bitch.",
        index: 52    
    },
    {
        quote: "W-W, wait!",
        index: 53
    },
    {
        quote: "People do things that make me do stuff",
        index: 54
    },
    {
        quote: "Bro, he's infected you so much",
        index: 55
    },
    {
        quote: "eeeeeEEE SHIT!",
        index: 56
    },
    {
        quote: "A reality, full of shit.",
        index: 57
    }
];

export default quotes;